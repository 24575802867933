import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const LocationCard = (props) => {
  return (
    <div className="col">
      <LazyLoadImage effect="blur" src={props.image} alt={props.imageAlt} />
      <h4>{props.title}</h4>
      <p>{props.text}</p>
      <div>
      <a href={props.url} target="_blank" rel="noopener noreferrer"><button className={props.color? props.color : "btn btn-bordered"}>{props.btnText}</button></a>&emsp;&emsp;&emsp;
      {props.anotherUrl ? <a href={props.anotherUrl} target="_blank" rel="noopener noreferrer"><button className="btn btn-bordered">{props.anotherBtnText}</button></a>: ''}
      </div>
    </div>
  );

}

export default LocationCard;